<template>
    <div>
        <HeaderCard :loading="loading" :filtroStatusEmAndamento="true" tittle="Solicitações em andamento" @onLoad="load" />
        <Chart :data="stackedData" :options="stackedOptions" class="h-30rem" type="bar" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        usersIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            statusIds: [],
            loading: false,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 25;
                        }
                    }
                }
            ]
        };
    },
    computed: {
        stackedData() {
            return {
                labels: this.dados.map((_d, index) => (index + 1).toString()),
                datasets: this.dados.map((d, index) => {
                    return {
                        label: `${index + 1} - ${d.status}`,
                        data: this.dados.map((p) => (p.statusId == d.statusId ? p.quantidade : null)),
                        porcentagem: this.dados.map(p => {
                          if (p.statusId == d.statusId) {
                              return p.porcentagem;
                          } else {
                              return null;
                          }
                        }),
                        backgroundColor: d.cor,
                        barThickness: 38,
                        skipNull: true,
                        intersect: false
                    };
                })
            };
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                interaction: {
                    mode: 'nearest'
                },
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057',
                            boxWidth: 10,
                            boxHeight: 10,
                            font: {
                                weight: 500
                            }
                        },
                    },
                    datalabels: {
                        formatter: (_value, context) => {
                            const porcentagem = Number(context.dataset.porcentagem[context.dataIndex]).toFixed(0);
                            const quantidade = context.dataset.data[context.dataIndex];
                            if (porcentagem && quantidade) return [`${porcentagem}%`, quantidade]
                        },
                        anchor: 'end',
                        align: 'top',
                        labels: {
                            value: {
                                color: 'black',
                                font: {
                                    size: 12
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            color: '#EBEDEF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#EBEDEF',
                        }
                    }
                }
            };
        }
    },

    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            this.statusIds = params.statusIds;
            this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes/status', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined,
                            statusIds: this.statusIds?.length ? this.statusIds : undefined,
                            userIds: this.usersIds?.length ? this.usersIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>
