<template>
    <div>
        <HeaderCard :loading="loading" tittle="Laudos PCD e enquadramentos" :buttonIcon="true" @onLoad="load" />
        <div class="flex flex-column">
            <p class="text-7xl font-normal m-0 mt-4 justify-content-center">{{ quantidadeTotal ?? '0' }}</p>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import formatarNumero from '@/utils/FormatadorNumero';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false
        };
    },
    computed: {
        quantidadeTotal() {
            return formatarNumero(this.dados[0]?.quantidade);
        },
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes/pcd', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>
